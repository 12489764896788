<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-700">收益账单</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-upload2" class="s-btn-exp a-mlr-17" @click="exportfile">导出</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="false" id="leSearch">
                <le-company-under-select label="加盟商" :multiple="true" :collapse="true" v-model="pageParam.params.companyIds"></le-company-under-select>
                <le-date-range label="账单时间"  :minDate.sync="pageParam.params.balanceDateStart" :maxDate.sync="pageParam.params.balanceDateEnd" />
                <le-select-local-search label="账单状态" v-model="pageParam.params.isConfirm" :options="billStatus" />
            </le-search-form>
            <le-pagview ref="revenuebills" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.revenuebillsList">
                <el-table ref="revenuebillsList" :data="tableData" :highlight-current-row="true" v-sticky="{ top: 0, parent:'.el-card__body' }" style="width: 100%">
                    <el-table-column prop="balanceDateText" label="日期" min-width="120">
                        <template slot-scope="{ row }">
                            <span class="a-c-blue font-point" @click="getBillsInfo(row)">{{ row.balanceDateText?row.balanceDateText:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="companyName" label="加盟商" min-width="200"></el-table-column>
                    <el-table-column prop="amount" label="总收益（元）" min-width="123">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.amount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="myOrderNum" label="自营订单量" min-width="103"></el-table-column>
                    <el-table-column prop="myOrderAmount" min-width="200">
                        <template slot="header" slot-scope="scope">
                            <div class="a-flex-cfsfs">
                                <span>自营订单总金额</span>
                                <span class="a-fs-12">(现金/红包/赠送金)(元)</span>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.myOrderAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="orderNum-myOrderNum" label="代理订单量" min-width="103">
                        <template slot-scope="{ row }">
                            <span>{{ row.orderNum-row.myOrderNum }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" min-width="200">
                        <template slot="header" slot-scope="scope">
                            <div class="a-flex-cfsfs">
                                <span>代理订单总金额</span>
                                <span class="a-fs-12">(现金/红包/赠送金)(元)</span>
                            </div>
                        </template>
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.orderAmount-row.myOrderAmount) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="自营收益(元)" min-width="123">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.myOrderIncome) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceType" label="代理收益(元)" min-width="123">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.amount-row.myOrderIncome) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="myStationSize" label="自营站点数量" min-width="123"></el-table-column>
                    <el-table-column prop="name" label="设备使用率" min-width="103">
                        <template slot-scope="{ row }">
                            <span>{{ row.deviceUsePercent?(row.deviceUsePercent*100).toFixed(2)+'%':"-"  }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="isConfirm" label="入账状态" min-width="103">
                        <template slot-scope="{ row }">
                            <span v-if="row.isConfirm == 0">未入账</span>
                            <span v-else-if="row.isConfirm == 1">已入账</span>
                            <span v-else>-</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="入账时间" min-width="160">
                        <template slot-scope="{ row }">
                            <span>{{ row.confirmTimeText?row.confirmTimeText:'-'  }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" min-width="75" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="详情" placement="top">
                                <img src="../../assets/icon/option-detail.png" class="a-wh-16" @click="getBillsInfo(scope.row)" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerLabel" slot-scope="scope" >
                    <div class="a-flex-rfsc" v-if="scope.val != -1">
                        <span class="a-fs-12 a-c-normal">总收益</span>
                        <span class="a-fs-16 a-c-master a-fw-b a-plr-7">￥{{scope.val}}</span>
                        <span class="a-fs-12 a-c-normal a-plr-10">共计：213189笔</span>
                    </div>
                </template>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
    import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                tableData: [],
                util: util,
                pageParam: {
                    url: this.$Config.apiUrl.getBalanceDateList,
                    method: "post",
                    params: {
                        isConfirm: this.$route.query.billsStatus?this.$route.query.billsStatus:'',
                        companyIds: [],
                        balanceDateStart: '',
                        balanceDateEnd: '',
                    },
                    freshCtrl: 1,
                },
                billStatus: [{
                    label: "未入账",
                    value: '0',
                }, {
                    label: "已入账",
                    value: '1',
                }],
                fileUrl: '',//文件导出url
            }
        },
        mounted () {
            
        },
        activated () {
            this.pageParam.freshCtrl++;
        },
        methods: {
            //获取设备列表
            setTableData(data,fileUrl) {
                this.tableData = data;
                this.fileUrl = fileUrl
            },
            handlerRest() {
                this.pageParam.params = {
                    isConfirm: '',
                    companyIds: [],
                    balanceDateStart: '',
                    balanceDateEnd: '',
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['revenuebills'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            // 查看收益账单详情
            getBillsInfo (datas) {
                this.$router.push({
                    path: '/revenueBills/revenueBills-details',
                    query: {
                        companyId: datas.companyId,
                        balanceDate: datas.balanceDateText
                    }
                })
            },
            exportfile () {
                if(!this.fileUrl) {
                    this.$message.warning('暂无可导出文件')
                    return
                }
                this.$confirm('是否确认导出文件？', '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.$Utils.downloadUrlFile(this.fileUrl, '收益账单列表')
                }).catch(_=>{ })
            }
        }
    }
</script>

<style lang="scss" scoped>
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
		/*width: 0;宽度为0隐藏*/
		width: 0 !important;
		height: 0 !important;
	}
	/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
		height: 100% !important;
	}
</style>